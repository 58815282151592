/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

require('aamu-highway/lib/browser/index.js')({});
const plugin = require('aamu-webpack/lib/plugin.js');
let derby = require('aamu-derby');
plugin(derby.App);
let app = (module.exports = derby.createApp('some', __filename));

const i18n = require('k-i18n');
app.serverUse(module, 'k-stylus');
app.loadViews(__dirname + '/../../views/app');
app.loadStyles(__dirname + '/../../styles/app');

app.component(require('sc-connection-alert'));
app.component(require('k-before-unload'));
app.component(require('k-dialog'));
app.component(require('aamu-toast'));
app.component(require('k-image-crop'));
app.component(require('sc-textarea-with-search'));
app.use(require('k-search3'), require('kansalaiskeskustelu-conf').regexp);
app.use(require('kk-comments'));
app.use(require('aamu-flash'), { useToast: true });
app.component(require('kansalaiskeskustelu-conf').client);
app.use(require('k-photo-upload-s3'));
app.component(require('k-file-upload-s3'));
app.use(require('aamu-date'));
app.component(require('k-infinitescroll'));
app.component(require('k-infinitescroll-graph'));
app.component(require('k-light-box'));
app.component(require('k-popup'));
app.component(require('sc-fetch-and-subscribe'));
app.component(require('k-unseen-indicator'));
app.component(require('k-emoji2'));
app.component(require('k-progress'));
app.component(require('k-loading-bar'));
app.component(require('k-textarea'));

app = i18n.localize(app, {
	availableLocales: ['fi'],
	urlScheme: false,
	defaultLocale: 'fi',
	resourcesPath: `${__dirname}/../locales/__locale__/__ns__.json`
}
);

// error catching, otherwise the app may crash if an uncaught error is thrown
app.on('model', model => //model.on 'change', '$connection.state', (state, oldstate) -> console.log arguments
model.on('error', err => console.error(err)));

app.proto.match = function(s, s2) {
	if (s && s2) {
		return s.indexOf(s2) === 0;
	}
};

require('./routes/users');
require('./routes/appeal');
require('./routes/notifications');
require('./routes/edit-profile');
require('./routes/admin-profile');
require('./routes/register');
require('./routes/status');
require('./routes/home');
require('./routes/threads');
require('./routes/channel');
require('./routes/channels');
require('./routes/search');
require('./routes/static');
require('./routes/video');
require('./routes/chat');
require('./routes/tunnistaudu');
//require './routes/fb-post'
require('./routes/twitter-post');
require('./components/home');
app.component(require('./components/profile'));
require('./components/register');
require('./components/public-profile');
require('./components/admin-profile');
require('./components/edit-profile');
require('./components/status');
app.component(require('./components/helpers'));
require('./components/status-list');
require('./components/status-delete');
require('./components/status-share');
require('./components/status-list-inf');
require('./components/status-flag');
require('./components/threads');
require('./components/notifications');
require('./components/channels');
require('./components/channel');
require('./components/card');
require('./components/video');
require('./components/chat');
require('./routes/public-profile');

require('./routes/status-input');
require('./components/status-input');
