/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/@:username/:posturl', (page, model, params, next) => {
    model.rpc('getpost', { username: params.username, posturl: params.posturl }, function (err) {
        if (model.get('_page.item')) {
            return page.render('status');
        } else {
            if (err === 'status:notfound') {
                return page.render('status:notfound');
            } else {
                return util.redirect(app, page, `/@${err}/${params.posturl}`);
            }
        }
    })
});

