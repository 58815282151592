/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../../app/util');

class StatusInput {
	constructor() {
		this.uploadedHeaderImage = this.uploadedHeaderImage.bind(this);
		this.setEmpty = this.setEmpty.bind(this);
		this.redirect = this.redirect.bind(this);
		this.keydown = this.keydown.bind(this);
	}

	static initClass() {
		this.prototype.isEmpty = null;
	}

	create(model) {
		this.setEmpty(model.get('item.data'));

		const Editor = require('kk-editor/dist/main.js').default;
		Editor()
			.then(editor => {
				editor.kmodel = model.root;
				editor.ui.view.editable.element.setAttribute('spellcheck', false);
				return editor.model.document.on('change:data', () => {
					const data = editor.getData();
					model.set('item.data', data);

					this.setEmpty(data);

					// do we want to get oembed
					if (0 && (data.indexOf('<oembed') !== -1)) {
						return util.toServer(model.root.at('_p.methods'), 'get-oembed', { id: model.get('item.id'), sidx: model.get('item.sidx') });
					}
				});
			})
			.catch(err => console.log(err));

		// this is "another id" which identifies the post.
		// this is used for images/videos that come from encoding after the post is published.
		if (!model.get('item.id') && !model.get('item.sidx')) {
			return model.set('item.sidx', model.id());
		}
	}

	uploadedHeaderImage(json) {
		console.log(json);
		if (json && json.url) {
			return this.model.set('item.image', json.url);
		}
	}

	setEmpty(data) {
		if (!data && !this.isEmpty) {
			this.isEmpty = true;
			return this.model.set('isempty', 'editor-is-empty');
		} else if (this.isEmpty === true) {
			this.model.del('isempty');
			return this.isEmpty = false;
		}
	}

	toggleEmojis() {
		if (this.model.get('showEmojis')) {
			return this.model.del('showEmojis');
		} else {
			return this.model.set('showEmojis', true);
		}
	}

	showEmojis() {
		return this.model.set('showEmojis', true);
	}

	hideEmojis() {
		return this.model.del('showEmojis');
	}

	redirect(postId, delay) {

		if (delay == null) { delay = 500; }
		const redirect = () => {
			if (postId) {
				const item = this.model.root.at(`items.${postId}`);
				return item.subscribe(err => {
					const username = this.model.root.get('_p.user.local.username');
					return util.redirect(this.app, this.page, `/@${username}/${item.get('url')}`);
				});
			} else {
				return util.redirect(this.app, this.page, "/");
			}
		};

		return setTimeout(redirect, delay);
	}

	delete(e) {
		return this.model.set('deleteConfirm', true);
	}

	cancelDelete(e) {
		return this.model.del('deleteConfirm');
	}

	confirmDelete(e) {
		this.model.del('deleteConfirm');
		return util.toServer(this.model.root.at('_p.methods'), 'delete-status', { id: this.model.get('item.id') }, () => {
			this.model.root.flash('info', 'Poistettu.', true);
			return this.redirect();
		});
	}

	done(e) {
		e.preventDefault();

		// save edited status
		if (this.model.get('item.id')) {
			this.model.set('saving', true);
			return util.toServer(this.model.root.at('_p.methods'), 'status-changed', this.model.get('item.id'), err => {
				if (err) {
					console.warn(err);
					return this.model.toast('error', err);
				} else {
					return this.redirect(this.model.get('item.id'));
				}
			});
			// new status
		} else if (((this.model.get('item.data') || '').length > 20) && ((this.model.get('item.title') || '').length > 1)) {
			const sidx = this.model.get('item.sidx');
			this.model.root.once('change', '_p.user.status', (val, oldv) => {
				if (oldv && !val) {
					return this.redirect(sidx, 1000);
				}
			});

			this.model.set('saving', true);
			const user = this.model.root.at('_p.user');
			return util.toServer(this.model.root.at('_p.methods'), 'new-status', { item: this.model.get('item') });
		} else {
			return this.model.toast('error', 'Otsikko tai blogiteksti näyttäisi puuttuvan tai olevan liian lyhyt...');
		}
	}


	keydown(ev) {
		if ((ev != null ? ev.keyCode : undefined) === 13) {
			return document.getElementById('editor').focus();
		}
	}
}
StatusInput.initClass();


app.component('status-input:do', StatusInput);
